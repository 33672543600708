<template>
  <div class="page">
    <NavBar />

    <div class="container">
      <div class="title">{{ $t('NavBar.positions') }}</div>
      <van-list
        v-if="list.length > 0"
        v-model:loading="loading"
        :finished="finished"
        :finished-text="$t('Bill.finished')"
        :immediate-check="false"
        offset="10"
        @load="onLoad"
      >
        <div class="list">
          <div class="item" v-for="item in list" :key="item">
            <div class="item-number">{{ item.no }}</div>
            <div class="row items-center justify-between q-mt-xs">
              <div class="item-name">{{ item.cname }}</div>
              <div class="green-tag">{{ $t('Trading.buy') }} 10.0000</div>
            </div>
            <div class="row items-center justify-between q-mt-sm">
              <div>{{ item.priceNew }}</div>
              <div class="text-green">208.10USDT</div>
            </div>
            <div class="q-mt-sm">{{ item.cdate }} → {{ item.edate }}</div>
            <div class="q-mt-sm text-white">AI Rro 10k</div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import NavBar from './components/NavBar'
import { List } from "vant";

export default {
  components: {
    [List.name]: List,
    NavBar
  },
  setup() {
    return {
      us: ref(null),
      list: ref([]),
      loading: ref(false),
      finished: ref(false),
      pageIndex: ref(1)
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getlist()
  },
  methods: {
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getdata()
    },
    getlist() {
      let _this = this
      _this.$request.post(
        "api/AccountUser/List",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          account: _this.$utils.getloc('tradAccount'),
          pageIndex: _this.pageIndex,
          pageSize: 10
        },
        (res) => {
          if(res.data.code == 100) {
            let data = res.data.data.acList1
            if(data.length == 0) {
              _this.finished = true
            } else {
              _this.finished = false
            }
            // console.log(data)
            _this.list.push(...data)
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.page {
  min-height: 100vh;
  background-color: #1F2937;
}

.container {
  padding: 15px;
}

.title {
  font-size: 23px;
  font-weight: bold;
  color: #fff;
}

.item {
  margin-top: 10px;
  padding: 15px;
  border: 1px solid #646464;
  border-radius: 4px;
  color: #949BA7;
}

.item-name {
  font-weight: bold;
  font-size: 20px;
  color: #fff;
}

.item-number {
  font-size: 15px;
}

.green-tag {
  padding: 2px 6px;
  border-radius: 4px;
  color: #fff;
  background-color: green;
}

.red-tag {
  padding: 2px 6px;
  border-radius: 4px;
  color: #fff;
  background-color: #e02020;
}
</style>