<template>
  <div class="bar">
    <div class="bar-wrap">
      <q-icon name="menu" color="grey" size="30px" @click="show = true"></q-icon>
      <div class="row">
        <van-popover v-model:show="showPopover" :actions="actions" placement="bottom-end" @select="onSelect">
					<template #reference>
            <img src="@/assets/img/base/land.png" width="30" class="q-mr-md" />
          </template>
				</van-popover>
        <van-popover v-model:show="showAccount" :actions="actions" placement="bottom-end" @select="onSelect">
					<div class="account">
            <div>{{ $utils.getloc('tradAccount') }}</div>
            <div class="out-btn" @click="logout">{{ $t('NavBar.logout') }}</div>
          </div>
          <template #reference>
            <q-icon name="person" color="grey" size="30px"></q-icon>
          </template>
				</van-popover>
      </div>
    </div>

    <div style="height: 60px;"></div>

    <!-- 菜单 -->
    <q-dialog v-model="show" position="left" :maximized="true">
      <div class="menu">
        <div class="row justify-end">
          <q-icon name="close" color="grey" size="20px" @click="show = false"></q-icon>
        </div>
        <div class="menu-item text-white" @click="tolink('Trading')">
          <q-icon name="home" size="22px" color="white"></q-icon>
          <div class="q-ml-sm">{{ $t('NavBar.shouye') }}</div>
        </div>
        <!-- <div class="menu-item text-white" @click="tolink('TradeAccount')">
          <q-icon name="account_balance_wallet" size="22px" color="white"></q-icon>
          <div class="q-ml-sm">账户</div>
        </div> -->
        <div class="menu-item text-white" @click="tolink('AiConfig')">
          <q-icon name="settings" size="22px" color="white"></q-icon>
          <div class="q-ml-sm">{{ $t('NavBar.aiConfig') }}</div>
        </div>
        <div class="menu-item text-white" @click="tolink('Positions')">
          <q-icon name="wysiwyg" size="22px" color="white"></q-icon>
          <div class="q-ml-sm">{{ $t('NavBar.positions') }}</div>
        </div>
        <div class="menu-item text-white" @click="tolink('ClosedPositions')">
          <q-icon name="wysiwyg" size="22px" color="white"></q-icon>
          <div class="q-ml-sm">{{ $t('NavBar.closedPositions') }}</div>
        </div>
      </div>
    </q-dialog>
  </div>
</template>

<script>
import { Popover } from 'vant';
import { ref } from 'vue';

export default {
  components: {
    [Popover.name]: Popover,
  },
  setup() {
    return {
      showPopover: ref(false),
      showAccount: ref(false),
      actions: ref([]),
      show: ref(false)
    }
  },
  created() {
    this.setLanguage()
  },
  methods: {
    tolink(path) {  
      this.$router.push(path)
      this.show = false
    },
    setLanguage() {
      this.actions = [
        {text: "中文繁體", val: "zh"},
        {text: "English", val: "en"},
        {text: "한국어", val: "kor"},
        {text: "Malay", val: "may"},
        {text: "Tiếng Việt", val: "vie"},
        {text: "ภาษาไทย", val: "th"},
        {text: "Indonesia", val: "id"},
      ]
    },
    onSelect(e) {
      this.$utils.setloc("language", e.val)
      this.$i18n.locale = e.val;
      this.$emit("setLanguage")
    },
    logout() {
      this.showAccount = false
      this.$router.replace('aiLogin')
    }
  }
}
</script>

<style scoped>
.bar-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9;
  border-bottom: 1px solid #374151;
  background-color: #1F2937;
}

.menu {
  position: relative;
  width: 270px;
  padding: 20px;
  background-color: #1E293B;
}

.menu-item {
  margin-top: 30px;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.account {
  padding: 10px 16px;
}

.account > div {
  width: 90px;
  text-align: center;
}

.out-btn {
  padding-top: 8px;
  margin-top: 8px;
  color: #e02020;
  border-top: 1px solid #ebedf0;
}
</style>